<template>
  <section class="search" :class="platform" data-t="search">
    <div>
      <h2 class="title">{{ t('search.title') }}</h2>
      <button v-if="isMobile" class="close-button" @click="close">
        <StIcon name="cross-large" :size="20" />
      </button>
    </div>
    <StTabs
      v-model="selectedTab"
      class="search-tabs"
      :tabs="tabs"
      type="separate"
    />
    <form class="search-form" @submit.prevent>
      <StInput
        v-model="searchValue"
        :placeholder="searchPlaceholder"
        autofocus
        data-t="search-input"
        icon-prefix="search"
        :size="isMobile ? 's' : 'l'"
      >
        <template #icon-posfix>
          <StIcon
            v-if="searchValue"
            :size="20"
            class="reset-icon"
            name="cross-large"
            @click="resetSearch"
          />
        </template>
      </StInput>
    </form>
    <div
      v-if="isSearchHistoryVisible"
      class="search-history"
      data-t="search-history-l1hl"
    >
      <div class="search-history-header">
        <p class="search-history-title">{{ t('search.lastResult') }}</p>
        <StButton
          type="text-tertiary"
          :size="isMobile ? 's' : 'm'"
          :label="t('search.clearHistory', { count: activeHistory.length })"
          data-t="clear-history-button"
          @click="clearHistory"
        />
      </div>
      <div class="search-history-labels">
        <StButton
          v-for="item in activeHistory"
          :key="item"
          class="search-history-button"
          :label="item"
          type="custom-color"
          custom-color="#1F1F29"
          :size="isMobile ? 's' : 'm'"
          data-t="search-history-button"
          @click="setSearch(item)"
        />
      </div>
    </div>
    <div class="search-wrapper">
      <div class="result">
        <div
          v-if="isLoadingGames && selectedTab === 'casino'"
          class="spinner-wrapper"
        >
          <StSpinner :size="32" />
        </div>
        <Transition v-else>
          <div v-if="hasMinSearchValue" class="result-list">
            <CasinoSearchResult
              v-if="selectedTab === 'casino' && casinoGames.length"
              class="casino-search-result"
              :items="casinoGames"
              :can-load-more="canLoadMoreGames"
              @load-more="handleLoadMore"
            />
            <BookmakerSearchResult
              v-else-if="selectedTab === 'sport' && bookmakerEvents.length"
              class="bookmaker-search-result"
              :items="bookmakerEvents"
            />
            <div v-else class="nothing-found" data-t="nothing-found">
              <StIcon class="nothing-icon" name="search-none" size="64" />
              <h2 class="nothing-header">{{ t('search.nothing') }}</h2>
              <p class="nothing-info">{{ t('search.changeRequest') }}</p>
            </div>
          </div>
        </Transition>
      </div>
      <Transition>
        <div
          v-if="isEmplyBlockVisible"
          class="empty-search"
          data-t="empty-search"
        >
          <img height="140" src="./empty.png" width="248" />
          <p>{{ t('search.empty') }}</p>
        </div>
      </Transition>
    </div>
  </section>
</template>

<script lang="ts" setup>
import BookmakerSearchResult from '@st/bookmaker/components/BookmakerSearchResult/BookmakerSearchResult.vue'
import CasinoSearchResult from '@st/casino/components/CasinoSearchResult/CasinoSearchResult.vue'
import { useSearch } from './useSearch'
import { useSearchHistory } from './useSearchHistory'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const {
  setSearch,
  resetSearch,
  searchValue,
  tabs,
  selectedTab,
  bookmakerEvents,
  casinoGames,
  isLoadingGames,
  canLoadMoreGames,
  loadMoreGames,
} = useSearch()
const { activeHistory, clearHistory } = useSearchHistory(
  searchValue,
  selectedTab,
)
const { platform, isMobile } = usePlatform()

const searchPlaceholder = computed(() =>
  selectedTab.value === 'casino'
    ? t('search.searchPlaceholderGames')
    : t('search.searchPlaceholderEvents'),
)

const hasMinSearchValue = computed(() => searchValue.value.length >= 3)

const isSearchHistoryVisible = computed(
  () => activeHistory.value.length > 0 && !hasMinSearchValue.value,
)

const isEmplyBlockVisible = computed(
  () => !hasMinSearchValue.value && !isSearchHistoryVisible.value,
)

function handleLoadMore() {
  if (!canLoadMoreGames.value) return
  loadMoreGames()
}

function close() {
  router.replace({
    query: { ...route.query, modal: undefined },
  })
}
</script>

<style scoped>
.search {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 596px;
  min-height: min(85vh, 760px);
  max-height: min(85vh, 760px);
  padding: var(--spacing-300);

  background: var(--background-base);
  border-radius: var(--border-radius-150, 12px);
  box-shadow: 0 0 80px -8px #1d1d2a;
}

.search-tabs {
  margin-bottom: var(--spacing-200);
}

.title {
  margin: 0;
  margin-bottom: var(--spacing-200);
  font: var(--desktop-text-2xl-semibold);
  color: var(--text-primary);
}

.reset-icon {
  cursor: pointer;
  color: var(--text-tertiary);

  &:hover {
    color: var(--text-primary);
  }
}

.result {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

.result-list {
  scrollbar-color: var(--background-tertiary);
  scrollbar-width: thin;

  overflow: hidden;
  overflow-y: auto;

  margin-right: calc(var(--spacing-200) * -1);
  padding-right: var(--spacing-200);
}

.search-wrapper {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;

  min-height: 0;
}

.casino-search-result {
  margin-top: var(--spacing-300);
}

.bookmaker-search-result {
  margin-top: var(--spacing-150);
}

.empty-search {
  position: absolute;
  top: 50%;
  right: var(--spacing-200);
  left: var(--spacing-200);
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-600) 0;

  p {
    margin: 0;
    margin-top: var(--spacing-300);
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
  }
}

.search-history-header {
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-300) 0 var(--spacing-200);
}

.search-history-title {
  margin: 0;
  font: var(--desktop-text-md-semibold);
}

.search-history-labels {
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--spacing-100);
}

.search-history-button {
  overflow: hidden;
  display: block;

  color: var(--text-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 0.25s ease-in-out,
    transform 0.35s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.nothing-found {
  position: absolute;
  top: 50%;
  right: var(--spacing-200);
  left: var(--spacing-200);
  transform: translateY(-50%);

  text-align: center;

  * {
    margin: 0;
  }
}

.nothing-icon {
  margin-right: var(--spacing-050);
  margin-bottom: var(--spacing-300);
  opacity: 0.24;
}

.nothing-header {
  margin-bottom: var(--spacing-025);
  font: var(--desktop-text-lg-medium);
}

.nothing-info {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.spinner-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;
  justify-content: center;
}

.mobile {
  &.search {
    width: 100%;
    min-height: 100%;
    padding: var(--spacing-150) var(--spacing-200);
  }

  .title {
    margin-bottom: var(--spacing-150);
    font: var(--mobile-title-2-semibold);
  }

  .search-tabs {
    margin-bottom: var(--spacing-150);
  }

  .casino-search-result {
    margin-top: var(--spacing-200);
  }

  .bookmaker-search-result {
    margin-top: var(--spacing-100);
  }

  .search-history-header {
    margin: var(--spacing-300) 0 var(--spacing-150);
  }

  .search-history-title {
    font: var(--mobile-text-medium);
  }

  .empty-search {
    img {
      width: 200px;
      height: auto;
    }

    p {
      margin-top: var(--spacing-200);
      font: var(--mobile-text-content-regular);
    }
  }
}

.close-button {
  all: unset;

  position: absolute;
  top: 24px;
  right: 4px;

  padding: var(--spacing-125);

  line-height: 0;
}
</style>
