import type { TSportEvent } from 'markets-store'
import { debounce } from '@st/utils'

export interface SearchOptions {
  delay?: number
  startIndex?: number
}
export interface SearchEventsByType {
  sport: TSportEvent[]
  cybersport: TSportEvent[]
}

const SEARCH_START_INDEX = 2

export function useBookmakerSearch(
  searchValue: Ref<string>,
  { delay = 300, startIndex = SEARCH_START_INDEX }: SearchOptions = {},
) {
  const bookmakerEvents = ref<TSportEvent[]>([])

  const findEvents = useFindByCompetitorName()
  function searchBookmakerEvents(query: string) {
    bookmakerEvents.value = findEvents(query)
  }

  function reset() {
    bookmakerEvents.value = []
  }
  const debounceSearch = debounce(searchBookmakerEvents, delay)
  watch(
    () => searchValue.value,
    (newValue) => {
      if (!newValue) reset()

      if (newValue.length > startIndex) debounceSearch(newValue)
    },
  )

  return {
    bookmakerEvents,
    reset,
  }
}
