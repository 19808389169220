import { debounce } from '@st/utils'

export interface SearchOptions {
  delay?: number
  startIndex?: number
}

const SEARCH_START_INDEX = 2

export function useCasinoSearch(
  searchValue: Ref<string>,
  { delay = 300, startIndex = SEARCH_START_INDEX }: SearchOptions = {},
) {
  const { isMobile } = usePlatform()

  const {
    items: casinoGames,
    canLoadMore,
    loadMore,
    isLoading,
    reset,
  } = useInfiniteList({
    url: '/casino/game/find',
    orderBy: [
      {
        fieldName: 'forbiddenByCountry' as const,
        sortOrder: 'ASC' as const,
      },
      {
        fieldName: 'name' as const,
        sortOrder: 'ASC' as const,
      },
    ],
    filterBy: computed(() => ({
      searchTerm: searchValue.value,
      ...(isMobile.value ? { forMobile: true } : { forDesktop: true }),
    })),
    perPage: 50,
    immediate: false,
  })

  const debounceSearch = debounce(loadMore, delay)

  watch(searchValue, (newValue) => {
    if (!newValue) reset()

    if (newValue.length > startIndex) {
      isLoading.value = true
      debounceSearch()
    }
  })
  return {
    casinoGames,
    reset,
    isLoading,
    canLoadMore,
    loadMore,
  }
}
