<template>
  <div class="bookmaker-search-result" data-t="bookmaker-search-result">
    <MSportList
      class="events-list"
      :render-full-tree="fullSportsTree"
      hide-markets
    />
  </div>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'
import MSportList from '../SportList/MSportList.vue'

const props = withDefaults(
  defineProps<{
    items: TSportEvent[]
  }>(),
  {
    items: () => [],
  },
)

const { items } = toRefs(props)
const { fullSportsTree } = useInitSportTree(items)
</script>

<style scoped>
.bookmaker-search-result {
  .events-list {
    --sport-header-title-top: 0;
    --sport-wrapper-padding-horizontal: 0;
  }
}
</style>
