import { debounce } from '@st/utils'
import type { SearchTab } from './useSearch'

interface SearchHistoryOptions {
  delay?: number
  minLength?: number
}

type SearchHistoryStorage = Record<SearchTab, string[]>

export function useSearchHistory(
  searchValue: Ref<string>,
  name: Ref<SearchTab>,
  { delay = 1500, minLength = 3 }: SearchHistoryOptions = {},
) {
  const history = useLocalStorage<SearchHistoryStorage>('searchHistoryMap', {
    casino: [],
    sport: [],
  })

  function addToHistory() {
    const value = searchValue.value.trim()
    if (value.length < minLength) return

    const oldHistory = history.value[name.value]
    if (oldHistory.includes(value)) return

    history.value[name.value] = [value, ...oldHistory].slice(0, 5)
  }

  function clearHistory() {
    history.value[name.value] = []
  }

  const debounceSearch = debounce(addToHistory, delay)
  watch(
    () => searchValue.value,
    (newValue) => {
      if (!newValue) return
      debounceSearch()
    },
  )

  const activeHistory = computed(() => history.value[name.value])

  return { activeHistory, clearHistory }
}
