<template>
  <div
    class="casino-search-result"
    :class="{ mobile: isMobile }"
    data-t="search-result"
  >
    <GameCard
      v-for="card in items"
      :key="card.gameId"
      :game="card"
      type="casino-search"
      class="casino-search-card"
    />
    <div ref="eol" />
  </div>
</template>
<script setup lang="ts">
import type { Game } from '../../types'
import GameCard from '../GameCard/GameCard.vue'

const { items, canLoadMore } = defineProps<{
  items: Game[]
  canLoadMore: boolean
}>()

const emit = defineEmits<{
  loadMore: [void]
}>()

const { isMobile } = usePlatform()

const endOfList = useTemplateRef<HTMLDivElement>('eol')

useInfiniteScroll({
  el: endOfList,
  loadMore: async () => emit('loadMore'),
  disabledObserver: toRef(() => !canLoadMore),
  options: { rootMargin: '800px', threshold: 0 },
})
</script>
<style scoped>
.casino-search-result {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-150);

  &.mobile {
    grid-template-columns: repeat(2, 1fr);
  }
}

.casino-search-card {
  width: 100%;
  min-width: 0;
  height: auto;
}
</style>
