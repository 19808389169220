import type { TSportEvent } from 'markets-store'
import type { Game } from '@st/casino/types'
import type { Tab } from '@st/ui/components/StTabs/types'
import { useSearchStore } from '../../stores/useSearchStore'

export type SearchTab = 'casino' | 'sport'

interface UseSearchReturn {
  tabs: Tab[]
  setSearch: (value: string) => void
  resetSearch: () => void
  searchValue: Ref<string>
  casinoGames: Ref<Game[]>
  selectedTab: Ref<SearchTab>
  isLoadingGames: Ref<boolean>
  bookmakerEvents: Ref<TSportEvent[]>
  canLoadMoreGames: Ref<boolean>
  loadMoreGames: () => Promise<void>
}

export function useSearch(): UseSearchReturn {
  const { t } = useI18n()

  const searchValue = ref('')

  function resetSearch() {
    searchValue.value = ''
  }

  function setSearch(value: string) {
    searchValue.value = value
  }

  const { bookmakerEvents } = useBookmakerSearch(searchValue)
  const { casinoGames, isLoading, loadMore, canLoadMore } =
    useCasinoSearch(searchValue)
  const { searchValue: prevSearchValue } = storeToRefs(useSearchStore())
  const { setSearchValue } = useSearchStore()

  const selectedTab = ref<SearchTab>('casino')
  const tabs: Tab[] = [
    {
      id: 'casino',
      label: t('search.casino'),
      icon: 'casino',
    },
    {
      id: 'sport',
      label: t('search.sport'),
      icon: 'all-sports',
    },
  ]

  const { currentPage } = useMatchLinkToRoute()

  onMounted(() => {
    switch (currentPage.value) {
      case 'sport':
        selectedTab.value = 'sport'
        break
      case 'cybersport':
        selectedTab.value = 'sport'
        break
      case 'casino':
        selectedTab.value = 'casino'
        break

      default:
        selectedTab.value = 'casino'
        break
    }
    searchValue.value = prevSearchValue.value
  })

  watch(searchValue, (newValue) => {
    setSearchValue(newValue)
  })

  return {
    tabs,
    setSearch,
    resetSearch,
    searchValue,
    selectedTab,
    bookmakerEvents,
    casinoGames,
    isLoadingGames: computed(
      () => isLoading.value && casinoGames.value.length === 0,
    ),
    canLoadMoreGames: canLoadMore,
    loadMoreGames: loadMore,
  }
}
